import React from 'react'


export default function ComingSoon() {
  document.title = 'IBP | Coming Soon...'
  return(
  <>
      <div className={'container flex center'}>
        <h1>Coming Soon...</h1>
      </div>
      </>
  );
}
