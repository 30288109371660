import React from 'react';
import Hamburger from './Hamburger';

export default function Header(){

  return(

      <Hamburger/>

  );

}
